import axios from '@/app/axios'
import { Moment } from 'moment'

class RidesReportsService {

  public async generateRidesPdfAsync(start: Moment, end: Moment) {
    const startISO = encodeURIComponent(start.toISOString(true))
    const endISO = encodeURIComponent(end.toISOString(true))
    const url = `ridesreports/pdf?start=${startISO}&end=${endISO}`
    const res = await axios.get<string>(url)
    return res.data
  }

  public async generateRidePdfAsync(id: string) {
    const url = `ridesreports/${id}/pdf`
    const res = await axios.get<string>(url)
    return res.data
  }
}

export default new RidesReportsService()
