























































































































































































































































































































































































































import Dates from "@/app/utils/dates"
import Itinerary from "@/itineraries/models/itinerary"
import RecurrenceDialog from "@/rides/components/recurrence-dialog/recurrence-dialog"
import InvoicesDialog from "@/invoices/components/invoices-dialog/invoices-dialog"
import Ride from "@/rides/models/ride"
import { RidePaymentMethod } from "@/rides/models/ride-payment-method"
import ridesReportsService from "@/rides/services/rides-reports-service"
import invoicesService from "@/invoices/services/invoices-service"
import Rides from "@/rides/utils/rides"
import Invoice from "@/invoices/models/invoice"
import cloneDeep from "lodash/cloneDeep"
import moment, { duration } from "moment"
import "moment-duration-format"
import RRule, { Options } from "rrule"
import { Language } from "rrule/dist/esm/src/nlp/i18n"
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import Customer from "@/customers/entities/customer"
import RideCategory from "@/rides/models/ride-category"
import ridesService from "@/rides/services/rides-service"

@Component
export default class RidesDialog extends Vue {
  @Prop({ default: () => [] })
  itineraries!: Itinerary[]
  rideCategories: RideCategory[] = []

  @Prop({ default: null })
  selectedRideCategory!: RideCategory | null

  @Prop({ default: false })
  loadingItineraries!: boolean

  @Ref()
  recurrenceDialog!: RecurrenceDialog

  @Ref()
  invoiceDialog!: InvoicesDialog

  private GERMAN: Language = {
    dayNames: [
      "Sonntag",
      "Montag",
      "Dienstag",
      "Mittwoch",
      "Donnerstag",
      "Freitag",
      "Samstag",
    ],
    monthNames: [
      "Jänner",
      "Februar",
      "März",
      "April",
      "Mai",
      "Juni",
      "Juli",
      "August",
      "September",
      "Oktober",
      "November",
      "Dezember",
    ],
    tokens: {},
  }

  paymentMethodItems = [
    { value: RidePaymentMethod.Cash, text: "Bar" },
    { value: RidePaymentMethod.Invoice, text: "Rechnung" },
    { value: RidePaymentMethod.DirectBilling, text: "Direktverrechnung" },
  ]

  dialog = false
  choiceDialog = false
  editMode = false
  editTemplate = false
  isPrinting = false
  ride = new Ride()
  cost = ""
  date = ""
  time = ""
  returnTripTime = ""
  returnTripDate = ""
  remarks = ""
  duration: number | null = null
  datePickerMenu = false
  timePickerMenu = false
  returnTimePickerMenu = false
  returnDatePickerMenu = false
  hasRemarksChanged = false

  @Watch("ride", { deep: true })
  rideChanged(newVal: Ride) {
    this.hasRemarksChanged = newVal.remarks != this.remarks
  }

  get costValue() {
    return parseFloat(this.cost ? this.cost.replace(",", ".") : "")
  }

  get isDisabled() {
    return (
      this.ride.isArchived ||
      this.ride.invoiceId !== null ||
      this.$auth.isTenant == false
    )
  }

  get isDriver() {
    return this.$auth.isDriver
  }

  get hasInvoice() {
    return this.ride.invoiceId != null
  }

  get isInvoiceRide() {
    return this.ride.paymentMethod === RidePaymentMethod.Invoice
  }

  get isDirectBillingRide() {
    return this.ride.paymentMethod === RidePaymentMethod.DirectBilling
  }

  get isCashBillingRide() {
    return this.ride.paymentMethod === RidePaymentMethod.Cash
  }

  get canDelete() {
    if (this.editMode === false) {
      return false
    }
    if (this.ride.isTemplate) {
      return this.editTemplate
    }
    return this.isDisabled === false
  }

  get scheduleText() {
    if (this.ride.schedule) {
      const options = RRule.parseString(this.ride.schedule)
      const rule = new RRule(options)
      return rule.toText((id) => {
        switch (id) {
          case "every":
            return "jede(n)"
          case "week":
            return "Woche"
          case "day":
            return "Tag"
          case "on":
            return "am"
          case "for":
            return "; "
          case "time":
            return "mal"
          case "times":
            return "mal"
          case "until":
            return "bis"
        }
        return id.toString()
      }, this.GERMAN)
    }
    return "Einmaliger Termin"
  }

  customerInfo(customer: Customer | null): string {
    if (!customer) {
      return ""
    }
    let arr: string[] = []
    if (customer && customer.phone) {
      arr.push(`Tel.: ${customer.phone}`)
    }
    if (customer && customer.remarks) {
      arr.push(`${customer.remarks}`)
    }
    return arr.length > 0 ? arr.join(" | ") : ""
  }

  create(ride?: Ride) {
    this.ride = ride || new Ride()
    this.ride.returnTrip = false
    this.returnTripTime = ""
    this.returnTripDate = ""
    this.remarks = ""
    this.cost = ""
    this.duration = null
    this.setDefaultItinerary(this.ride)
    this.setDateAndTime(this.ride)
    this.dialog = true
    this.editMode = false
  }

  edit(ride: Ride) {
    this.ride = cloneDeep(ride)
    this.hasRemarksChanged = false
    this.remarks = ride.remarks
    this.cost = this.ride.cost
      ? this.ride.cost.toFixed(2).replace(".", ",")
      : ""
    // Wenn wir ein Template bearbeiten, dann lassen wir den Benutzer
    // entscheiden, ob das Template editiert oder der Einzeltermin angelegt
    // werden soll.
    if (ride.isTemplate) {
      this.choiceDialog = true
    } else {
      this.editAsRide()
    }
    this.editMode = true
  }

  createInvoiceFromRide() {
    this.invoiceDialog.createFromRide(this.ride)
  }

  async createInvoice(invoice: Invoice) {
    await invoicesService.createAsync(invoice)
    this.ride.invoiceId = invoice.id // => Auftrag readonly machen
    this.$emit("reload")
  }

  setAsSettled() {
    if (this.editMode) {
      this.ride.isHidden = true
      this.$emit("update", this.ride)
      this.dialog = false
    }
  }

  editAsTemplate() {
    // Das Anfangsdatum für das Template befindet sich in dtBegin. Wir setzen
    // date = dtBegin um es bearbeiten zu können.
    this.ride.date = this.ride.dtBegin
    this.setDateAndTime(this.ride)
    this.editTemplate = true
    this.choiceDialog = false
    this.dialog = true
  }

  editAsRide() {
    this.setDateAndTime(this.ride)
    this.editTemplate = false
    this.choiceDialog = false
    this.dialog = true
  }

  toggleReturnTrip() {
    if (this.ride.returnTrip && this.returnTripTime == "") {
      this.returnTripTime = this.time
      this.returnTripDate = this.date
    }
  }

  async save() {
    this.ride.cost = this.costValue

    if (await this.$validator.validateAll()) {
      if (this.$auth.isDriver) {
        if (this.editMode) {
          // Beim Fahrer nichts machen, außer geänderte Daten weitergeben
          if (!this.editTemplate) {
            Rides.toRegularRide(this.ride)
          }
          this.$emit("update", this.ride)
        }
      } else {
        // Rechnungsfahrten sind keine Schnellbuchungen.
        if (this.isInvoiceRide) {
          this.ride.isQuickRide = false
        }
        // DateTime aus getrennten Datums- und Zeitfeldern.
        this.ride.date = Dates.combine(this.date, this.time)

        if (this.ride.returnTrip) {
          this.ride.returnDate = Dates.combine(this.returnTripDate, this.returnTripTime)
        }
        // Dauer in Minuten umwandeln zu C# Duration
        if (this.duration) {
          this.ride.duration = Dates.minutesAsDuration(this.duration)
        }

        if (this.editMode) {
          if (this.ride.isTemplate) {
            // Es handelt sich um ein Template.
            if (this.editTemplate) {
              // Wir editieren das dahinterliegende Template des Auftrags.
              // this.ride.isTemplate = true
              this.$emit("update", this.ride)
            } else {
              Rides.toRegularRide(this.ride)
              this.$emit("save", this.ride)
            }
          } else {
            // Speichere die Änderungen am Auftrag.
            this.$emit("update", this.ride)
          }
        } else {
          // Wenn der Schedule string nicht leer ist, handelt es sich um ein
          // Template.
          this.ride.isTemplate = this.ride.schedule !== ""
          this.$emit("save", this.ride)
        }
      }
      this.dialog = false
    }
  }

  remove() {
    this.$emit("remove", this.ride)
    this.dialog = false
  }

  async print() {
    this.isPrinting = true
    const pdfUrl = await ridesReportsService.generateRidePdfAsync(this.ride.id)
    window.open(pdfUrl)
    this.isPrinting = false
  }

  cancel() {
    this.$emit("cancel")
    this.ride.returnTrip = false
    this.dialog = false
  }

  cancelChoice() {
    this.$emit("cancel")
    this.choiceDialog = false
  }

  resetReturnTrip() {
    this.ride.returnTrip = false
  }

  openRecurrenceDialog() {
    this.recurrenceDialog.openRFC(this.ride.schedule)
  }

  saveSchedule(options: Partial<Options>) {
    const rule = new RRule(options)
    this.ride.schedule = rule.toString()
  }

  setDateAndTime(ride: Ride) {
    const mom = moment(ride.date)
    this.date = mom.format("YYYY-MM-DD")
    this.time = mom.format("HH:mm")
    if (ride.duration) {
      this.duration = duration(ride.duration).asMinutes()
    }
  }

  setDefaultItinerary(ride: Ride) {
    const it = this.itineraries.find((i) => i.isDefault)
    if (it) {
      this.ride.itineraryId = it.id
      this.ride.itineraryName = it.name
    }
  }
  private async mounted() {
    this.rideCategories = await ridesService.getRideCategoriesAsync()
  }
}
