











import {
  Component,
  Mixins,
  Prop,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator"
import ridesService from "@/rides/services/rides-service"

@Component
export default class DriverSelect extends Vue {
  @Prop()
  private value!: string
  private driverName: string = ""
  private driverNames: string[] = []

  private input() {
      this.$emit('input', this.driverName)
  }

  private async mounted() {
    this.driverName = this.value
    const result = await ridesService.getDriversAsync()
    this.driverNames = result.map((d) => `${d.firstName} ${d.lastName}`)
  }
}
