import Guid from "@/app/utils/guid";
import Customer from "@/customers/entities/customer";
import { InvoiceState } from "@/invoices/models/invoice-state";
import moment from "moment";
import { RidePaymentMethod } from "./ride-payment-method";

export default class Ride {
  public id = Guid.empty();
  public jobNr: string = "";
  public tenantId = Guid.empty();
  public date = moment().format("YYYY-MM-DD HH:mm");
  public returnDate = moment().format("YYYY-MM-DD HH:mm");
  public returnTrip = false;
  public dtBegin = moment().format("YYYY-MM-DD HH:mm");
  public itineraryId: string | null = null;
  public itineraryName = "";
  public customer: Customer | null = null;
  public numberOfPassengers: number | null = null;
  public cost: number | null = null;
  public reminderCharges: number | null = null;
  public distance: number | null = null;
  public duration: string | null = null;
  public origin = "";
  public destination = "";
  public driverName = "";
  public vehicle = "";
  public remarks = "";
  public invoiceId: string | null = null;
  public recurringRideId: string | null = null;
  public isQuickRide: boolean = false;
  public isTemplate: boolean = false;
  public invoiceState = InvoiceState.Pending;
  public paymentMethod: RidePaymentMethod = 0;
  public schedule: string = "";
  public isArchived = false;
  public isHidden = false;
  public mapsLink =
    "https://www.google.com/maps/dir/" + origin + "/" + this.destination + "/";
}
