import Period from "@/common/components/periods/period";
import { RidesOrder } from "./rides-order";

export default class RidesFilter {
  public onlyWithCost: boolean = false;
  public openRidesOnly: boolean = false;
  public excludeDirectBilling: boolean = false;
  public excludeHidden: boolean = true;
  public text: string = "";
  public period: Period | null = null;
  public count: number = 0;
  public order: RidesOrder = RidesOrder.DateDesc;
}
